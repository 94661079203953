import { PageProps, graphql } from "gatsby";
import { PageWidth, Section } from "@util/standard";
import React, { useEffect, useRef, useState } from "react";
import { findFormElements, formatUrlHiddenFields, isBrowser } from "@util/helper";
import { useDynamicsFormLoad, useTimeout } from "@util/hooks";

import { Header } from "@shared";
import { Helmet } from "react-helmet";
import { Query } from "@graphql-types";
import styled from "styled-components";

interface Props extends PageProps {
  data: Query;
}

const StyledFormWrapper = styled.div`
  width: fit-content;
  height: fit-content;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 10%;
`;

const EventRegistration = ({ data: { sanityHeader } }: Props) => {
  const dynamicsFormRef = useRef(null);
  const formRef = useRef(null);
  console.log({ dynamicsFormRef });
  const [scriptLoaded, setScriptLoaded] = useState(false);

  const addFormScript = () => {
    // @ts-ignore
    if (window.MsCrmMkt?.MsCrmFormLoader == null) {
      const newScript = document.createElement("script");
      newScript.src =
        "https://mktdplp102cdn.azureedge.net/public/latest/js/form-loader.js?v=1.85.2002";
      newScript.async = true;
      document.head.appendChild(newScript);
      newScript.onload = () => {
        setScriptLoaded(true);
      };
    }
  };

  useEffect(() => {
    addFormScript();
  }, []);

  useEffect(() => {
    console.log({ scriptLoaded });

    if (isBrowser() && scriptLoaded) {
      // @ts-ignore

      window.MsCrmMkt?.MsCrmFormLoader.on("afterFormLoad", function () {
        console.log("loaded");
        const hiddenFieldValues = formatUrlHiddenFields();
        console.log(hiddenFieldValues);

        if (dynamicsFormRef.current && hiddenFieldValues) {
          const elements = findFormElements(dynamicsFormRef);
          console.log(elements);

          elements?.forEach(element => {
            console.log(element);

            if (element.id === "db3db97b-e8dc-ed11-a7c7-002248143ca0" && hiddenFieldValues) {
              console.log("found element changing value");
              const guid = hiddenFieldValues.find(
                value => value && (value.name === "guid" || value.name === "GUID"),
              );

              if (guid) {
                const replace = guid.value.replace("{", "").replace("}", "");
                element.value = replace;
                console.log({ replace });
              }
            }
          });
        }
      });
    }
  }, [scriptLoaded]);

  return (
    <>
      <div id={process.env.GATSBY_DYNAMICS_CONFIG_ID}></div>
      <div
        className="d365-mkt-config"
        style={{ display: "none" }}
        data-website-id={process.env.GATSBY_DYNAMICS_WEBSITE_ID}
        data-hostname={process.env.GATSBY_DYNAMICS_DATA_HOSTNAME}
      ></div>
      <Header data={sanityHeader} />
      <Section>
        <PageWidth>
          <StyledFormWrapper></StyledFormWrapper>
        </PageWidth>
      </Section>
    </>
  );
};

export default EventRegistration;

export const query = graphql`
  query eventRegistrationQuery {
    sanityHeader {
      ...sanityHeader
    }
  }
`;
